<template>
  <div v-if="traducoes.length">
    <Edit :config="currentTraducao" :update="update" :close="closeEdit"></Edit>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Chave</th>
            <th>Descrição</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="traducao in traducoes" v-bind:key="traducao.chave">
            <td>{{ traducao.desc }}</td>
            <td>
              <div>
                <strong>{{ traducao.valor ? traducao.valor.label : 'Título não informado' }}</strong>
              </div>
              <div>
                <small>{{ traducao.valor ? traducao.valor.valor : 'Descrição não informada' }}</small>
              </div>
            </td>
            <td class="actions">
              <CButton color="primary" size="sm" @click="openEdit(traducao)">
                <fa-icon icon="pencil-alt" />
              </CButton>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import clone from "just-clone";
import { upload, get } from "../../helpers/apiRequest";
import Edit from "./Edit";

export default {
  name: "traducoes",
  components: {
    Edit
  },
  props: ["traducoes", "updateTraducao"],
  data() {
    return {
      currentTraducao: {}
    };
  },
  filters: {
    tipo(valor, tipo, opcoes) {
      switch (tipo) {
        case "SELECT":
          return opcoes[valor] || valor;

        case "BOOLEAN":
          if (valor) return "Sim";
          return "Não";

        case "TEL":
        case "EMAIL":
          return `${valor.valor}`;

        default:
          return valor;
      }
    }
  },
  methods: {
    update(traducao) {
      this.updateTraducao(traducao).then(() => this.closeEdit());
    },
    openEdit(traducao) {
      this.currentTraducao = clone(traducao);
      this.$modal.show("configEdit");
    },
    closeEdit() {
      this.$modal.hide("configEdit");
    }
  }
};
</script>