<template>
  <modal
    name="configEdit"
    height="auto"
    width="600"
    :scrollable="true"
    :clickToClose="false"
  >
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <div class="form">
          <div class="form-single">
            <div class="xrow">
              <div class="col">
                <div class="form-group">
                  <label for="">{{ config ? config.desc : "" }}</label>
                  <small class="font-sm">Chave: {{ config.chave }}</small>

                  <TipoInput
                    v-if="config.tipo !== 'TYPE_KEYVALUE'"
                    v-model="config.valor"
                    :config="config"
                    :valor="config.valor"
                    :tipo="config.tipo"
                    :opcoes="config.opcoes"
                  />

                  <TipoInput
                    v-if="config.tipo === 'TYPE_KEYVALUE'"
                    v-for="(opcaoTipo, opcaoNome) in config.opcoes"
                    v-model="config.valor[opcaoNome]"
                    :key="opcaoNome"
                    :config="{
                      valor: config.valor[opcaoNome],
                      label: opcaoNome,
                    }"
                    :tipo="opcaoTipo"
                    :opcoes="config.opcoes"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        :class="{ 'button button-success': true, 'button-disabled': false }"
        v-on:click="update(config)"
      >
        Atualizar
      </button>
      <button class="button" v-on:click="close">Fechar</button>
    </div>
  </modal>
</template>
<script>
import TipoInput from "./TipoInput";
import CloseModal from "../../components/CloseModal";

export default {
  name: "configEdit",
  components: { TipoInput, CloseModal },
  props: ["update", "close", "config"],
};
</script>
